<template>
  <div>
    <div v-if="otpInfo && otpInfo.mfaEnrolled">
      You're account is already enrolled
    </div>
    <div v-if="otpInfo && !otpInfo.mfaEnrolled">
      <h2 style="font-size: 1.5rem; margin-bottom: 3rem;">Request One Time Password</h2>

      <ValidationObserver slim v-slot="{ invalid }">
        <ValidationProvider v-if="otpInfo.mfaPhoneSupported"
          rules="required|min:10"
          tag="div"
          class="field"
          v-slot="{ errors }"
        >
          <div class="control">
            <input
              name="Phone Number"
              class="input"
              type="number"
              placeholder="Phone Number"
              v-model="phone"
            />
            <small class="has-text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <ValidationProvider
          v-if="otpInfo.mfaEmailSupported"
          rules="required"
          tag="div"
          class="field"
          v-slot="{ errors }"
        >
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Email"
              v-model="email"
              name="Email"
            />
            <small class="has-text-danger">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>

        <div class="buttons">
          <button
            class="button is-success"
            :disabled="invalid || $store.state.loading"
            @click="save"
          >
            Save
          </button>

        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      otpInfo: null,
      phone: null,
      email: null
    }
  },
  mounted() {
    let otpInfoString = sessionStorage.getItem('otp_info');
    if (otpInfoString) {
      this.otpInfo = JSON.parse(otpInfoString);
      this.phone = this.otpInfo.mfaPhone || '';
      this.email = this.otpInfo.mfaEmail || '';
    }
  },
  methods: {
    async save() {
      this.$store.commit("SET_GLOBAL_NOTIFICATION");
      this.$store.commit("SET_LOADING", true);
      try {
        await this.$store.dispatch("enrollOTP", {
          otpKey: this.otpInfo.otpKey,
          phone: this.phone,
          email: this.email
        });
        this.otpInfo.mfaEmail = this.email;
        this.otpInfo.mfaPhone = this.phone;
        sessionStorage.setItem('otp_info', JSON.stringify(this.otpInfo));
        this.$router.push({ name: 'otpRequest', params: { instanceId: this.$route.params.instanceId } });
      } catch (e) {
        this.$store.commit("SET_GLOBAL_NOTIFICATION", {
          showNotification: true,
          notificationMessage: `Error Saving Password; ${e}`,
          notificationType: "error"
        });
      } finally {
        this.$store.commit("SET_LOADING", false);
      }
    }
  }
};
</script>
